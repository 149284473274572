import { postBatchApiData } from "@hooks/utils/api";
import { catchSentryError } from "@utils/sentry";
import { useState, useCallback } from "react";
import { encodeContractorEmployeeProject } from "social-pro-common/decoders/contractorEmployeeProject";
import { ContractorEmployeeProjectLineItem } from "social-pro-common/interfaces/contractorEmployeeProject";

const useProjectEmployeeAssignment = () => {
  const [error, setError] = useState<string | null>(null);

  const updateEmployeeAssignment = useCallback(
    async (assignmentsToUpsert: ContractorEmployeeProjectLineItem[]) => {
      try {
        const encodedAssignments = assignmentsToUpsert.map((assignment) =>
          encodeContractorEmployeeProject(assignment),
        );
        await postBatchApiData(
          "upsertEmployeeProjectAssignment",
          "projectEmployee",
          encodedAssignments,
        );
        return true;
      } catch (error) {
        catchSentryError(error);
        setError("Could not update employee");
        return false;
      }
    },
    [postBatchApiData, encodeContractorEmployeeProject, catchSentryError],
  );

  return {
    error,
    updateEmployeeAssignment,
  };
};

export default useProjectEmployeeAssignment;
