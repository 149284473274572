import { useLabourHour } from "@hooks/crud/labourHours/useLabourHours";
import { useProjectAssignedEmployee } from "@hooks/crud/projectEmployee/useProjectAssignedEmployee";
import { downloadFile, uploadFile } from "@hooks/utils/useUpload";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Grid,
  Skeleton,
  Stack,
  Typography,
  IconButton,
  Box,
  Tooltip,
  Zoom,
  CircularProgress,
} from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { CircularProgressWithLabel } from "@stories/molecules/CircularProgressWithLabel/CircularProgressWithLabel";
import {
  EmployeeTypeTabs,
  EmploymentTypeTabOption,
} from "@stories/molecules/EmployeeTypeTabs/EmployeeTypeTabs";
import LabourHourImport, {
  ImportStep,
} from "@stories/molecules/LabourHourImport/LabourHourImport";
import { LabourHoursTable } from "@stories/molecules/LabourHoursTable/LabourHoursTable";
import { SearchInput } from "@stories/molecules/SearchInput/SearchInput";
import { catchSentryError } from "@utils/sentry";
import { stringToSearchQuery } from "@utils/utils";
import { ChangeEvent, FocusEvent, useRef, useState } from "react";
import { ToastOptions, toast } from "react-toastify";
import { PackageCommentType } from "social-pro-common/entities/packageComment";
import { ReportDocumentType } from "social-pro-common/entities/reportDocument";
import { LabourHourLineItem } from "social-pro-common/interfaces/labourHour";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import { packageCommentTypeToString } from "social-pro-common/interfaces/packageComment";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import {
  ReportDocumentLineItem,
  createDefaultReportDocument,
} from "social-pro-common/interfaces/reportDocument";
import { formatReportDate, stringToDate } from "social-pro-common/utils/date";
import { getDocumentName } from "social-pro-common/utils/string";

interface LabourHoursProps {
  isLoading: boolean;
  project?: ProjectLineItem;
  contractorPackageId?: string;
  userOrganisation?: OrganisationLineItem;
  reportId: string;
  commentMap: Map<PackageCommentType, string>;
  isSubmitting: boolean;
  readOnly?: boolean;
  reportDocuments: ReportDocumentLineItem[];
  createReportDocument: (reportDocument: ReportDocumentLineItem) => void;
  updateReportDocument: (reportDocument: ReportDocumentLineItem) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
  updateComment: (
    packageType: PackageCommentType,
    comment: string,
    save: boolean,
  ) => Promise<void>;
  handlOnBack?: () => void;
}

export const LabourHours = ({
  commentMap,
  contractorPackageId,
  createReportDocument,
  handlOnBack,
  isLoading,
  isSubmitting,
  project,
  readOnly,
  reportDocuments,
  reportId,
  setIsSubmitting,
  updateComment,
  updateReportDocument,
  userOrganisation,
}: LabourHoursProps) => {
  const fileInput = useRef<HTMLInputElement | null>(null);

  const onFileInput = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const [step, setStep] = useState<ImportStep>(ImportStep.Init);
  const [employeeType, setEmployeeType] = useState<EmploymentTypeTabOption>(
    EmploymentTypeTabOption.Standard,
  );

  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [searchValue, setSearchValue] = useState<string>();

  const [isUploading, setIsUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const [uploadProgress, setUploadProgress] = useState(0);

  const handleSearchChange = () => {
    setSearchValue(inputValue.length > 0 ? inputValue : undefined);
  };

  const { isLabourHourLoading, labourHours, upsertLabourHours } = useLabourHour(
    project?.id,
    reportId,
    contractorPackageId,
  );

  const {
    employeeCount,
    employees,
    isEmployeeLoading,
    labourHoursForEmployee,
  } = useProjectAssignedEmployee(
    userOrganisation?.id,
    project?.id,
    contractorPackageId,
    employeeType === EmploymentTypeTabOption.Standard,
    stringToSearchQuery(searchValue),
    page,
    reportId,
  );

  const [currentSelectedEmployees, setCurrentSelectedEmployees] = useState<
    string[]
  >([]);

  const handleUpdateLabourHour = async (labourHours: LabourHourLineItem[]) => {
    setIsSubmitting(true);
    setCurrentSelectedEmployees((prev) => [
      ...prev,
      ...labourHours.map((lh) => lh.employeeId),
    ]);
    await upsertLabourHours(labourHours);
    setCurrentSelectedEmployees((prev) =>
      prev.filter((e) => !labourHours.map((lh) => lh.employeeId).includes(e)),
    );
    setIsSubmitting(false);
  };

  const commentValue = commentMap.get(PackageCommentType.Hour) || "";

  const onDownload = async (uri: string, policyName: string) => {
    setIsDownloading(true);
    await downloadFile(uri, policyName);
    setIsDownloading(false);
  };

  const labourHourReportDocument = reportDocuments.find(
    (d) => d.reportDocumentType === ReportDocumentType.LabourHour,
  );

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setUploadProgress(0);
    if (
      e.currentTarget?.files &&
      e.currentTarget?.files.length > 0 &&
      contractorPackageId
    ) {
      const file = e.currentTarget?.files[0] || null;
      if (file) {
        setIsUploading(true);

        const fileKey = `supporting-documents/${contractorPackageId}/${reportId}/${file.name}`;
        await uploadFile(
          fileKey,
          file,
          (progress: any) => {
            const total = (progress.loaded / progress.total) * 100;
            setUploadProgress(total);
            if (total === 100) {
              toast("Upload complete!", {
                type: "success",
              } as ToastOptions);
            }
          },
          (error: any) => {
            setUploadProgress(0);
            catchSentryError(error);
            toast("Upload failed - something went wrong!", {
              type: "error",
            } as ToastOptions);
            setIsUploading(false);
          },
        );

        if (labourHourReportDocument) {
          await updateReportDocument({
            ...labourHourReportDocument,
            uri: fileKey,
          });
        } else {
          await createReportDocument(
            createDefaultReportDocument(
              contractorPackageId,
              reportId,
              fileKey,
              ReportDocumentType.LabourHour,
            ),
          );
        }
        // const urlToImage = URL.createObjectURL(file);
        setIsUploading(false);
      }
    }
  };

  const loading =
    (isLoading || isLabourHourLoading || isEmployeeLoading) && !isSubmitting;

  return (
    <>
      <Grid
        container
        sx={{ margin: "0", padding: "30px 40px 0" }}
        className="borderTop"
      >
        <Grid item md={6}>
          {loading ? (
            <Stack direction="row" spacing={2}>
              {handlOnBack ? (
                <Skeleton variant="circular">
                  <IconButton color="primary" size="large">
                    <ArrowBackIosNewOutlinedIcon />
                  </IconButton>
                </Skeleton>
              ) : null}
              <Stack>
                <Skeleton animation="wave">
                  <Typography variant="h2">
                    {"Project Reporting 2000"}
                  </Typography>
                </Skeleton>
                <Skeleton animation="wave">
                  <Typography variant="subtitle1">
                    {"September 2000"}
                  </Typography>
                </Skeleton>
              </Stack>
            </Stack>
          ) : (
            <Stack direction="row" spacing={2}>
              {handlOnBack ? (
                <Tooltip
                  TransitionComponent={Zoom}
                  placement="top"
                  title="Back to package details"
                >
                  <IconButton
                    data-test-id="back-button-top"
                    size="large"
                    onClick={handlOnBack}
                    sx={{
                      color: "black",
                      height: "auto",
                      padding: "5px",
                      width: "auto",
                    }}
                  >
                    <ArrowBackIosNewOutlinedIcon />
                  </IconButton>
                </Tooltip>
              ) : null}
              <Stack>
                <Typography variant="h2">{`Labour Hours Reporting`}</Typography>
                <Typography variant="subtitle1">
                  {`${project?.projectName}: ${
                    reportId ? formatReportDate(stringToDate(reportId)) : ""
                  }`}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "flex-end",
            textAlign: "right",
          }}
        >
          <Stack direction="row" spacing={2}>
            <Box className="newStyleField monthSelect">
              <SearchInput
                loading={loading}
                inputValue={inputValue}
                setInputValue={setInputValue}
                handleSearchChange={handleSearchChange}
              />
            </Box>
            {!readOnly ? (
              <StyledButton
                data-test-id="import-button"
                className="blackBtn"
                loading={loading}
                variant="contained"
                disabled={loading}
                onClick={() => {
                  setIsOpen(true);
                }}
                startIcon={<CloudUploadIcon />}
              >
                Import
              </StyledButton>
            ) : null}
          </Stack>
        </Grid>
      </Grid>
      <Grid container sx={{ margin: "0", padding: "0px 40px 40px" }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            padding: "30px 0px 0 !important",
          }}
        >
          <EmployeeTypeTabs
            isLoading={loading}
            value={employeeType}
            setEmployeeType={(employmentLevel: EmploymentTypeTabOption) => {
              setPage(0);
              setEmployeeType(employmentLevel);
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} sx={{ paddingTop: "10px" }}>
          <LabourHoursTable
            isLoading={loading}
            isSubmitting={isSubmitting}
            reportId={reportId}
            labourHours={labourHours}
            employees={employees}
            employeeCount={employeeCount}
            setPage={setPage}
            showStudiedHours={employeeType === EmploymentTypeTabOption.Trainee}
            previousEmployeeLabourHours={labourHoursForEmployee}
            currentSelectedEmployees={currentSelectedEmployees}
            upsertLabourHours={handleUpdateLabourHour}
            contractorPackageId={contractorPackageId}
            filterName={inputValue}
            userOrganisation={userOrganisation}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={12} mt={2}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent={"flex-end"}
            alignItems="center"
          >
            {loading ? (
              <Skeleton animation="wave">
                <Typography>Supporting Documentation</Typography>
              </Skeleton>
            ) : (
              <Typography>Supporting Documentation</Typography>
            )}
            {labourHourReportDocument ? (
              isDownloading ? (
                <CircularProgress />
              ) : (
                <StyledButton
                  loading={loading}
                  color="primary"
                  size="large"
                  disabled={isUploading || isDownloading}
                  onClick={() =>
                    onDownload(
                      labourHourReportDocument.uri,
                      getDocumentName(
                        labourHourReportDocument.uri,
                        labourHourReportDocument.uri,
                      ),
                    )
                  }
                  className="blackBtn grey-outline-btn"
                  sx={{
                    color: "white",
                    fontSize: "14px",
                    lineHeight: "16px",
                    padding: "10px 15px",
                  }}
                  startIcon={<DownloadIcon />}
                >
                  Download
                </StyledButton>
              )
            ) : null}

            {isUploading ? (
              <CircularProgressWithLabel value={uploadProgress} />
            ) : (
              <label htmlFor="containedUploadButtonFile">
                <input
                  ref={fileInput}
                  hidden
                  type="file"
                  style={{
                    cursor: "pointer",
                    opacity: 0,
                    padding: 0.5,
                    position: "fixed",
                    zIndex: 1,
                  }}
                  onChange={onUpload}
                />
                <StyledButton
                  loading={loading}
                  color="primary"
                  size="large"
                  data-test-id="policy-details-button"
                  disabled={isUploading || isDownloading}
                  onClick={() => {
                    onFileInput();
                  }}
                  startIcon={<CloudUploadIcon />}
                  className={"blackBtn"}
                  sx={{
                    color: "white",
                    fontSize: "14px",
                    lineHeight: "16px",
                    padding: "10px 15px",
                  }}
                >
                  {labourHourReportDocument ? "Reupload" : "Upload"}
                </StyledButton>
              </label>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} md={12} mt={2}>
          <StyledTextField
            data-test-id="hours-comments-input"
            variant="outlined"
            loading={loading && !isSubmitting}
            disabled={loading || isSubmitting || readOnly}
            id={`${packageCommentTypeToString(PackageCommentType.Hour)}`}
            name={`${packageCommentTypeToString(PackageCommentType.Hour)}`}
            label={`${packageCommentTypeToString(
              PackageCommentType.Hour,
            )} Comments`}
            fullWidth
            maxLength={250}
            rows={5}
            multiline
            margin="dense"
            onChange={async (e: ChangeEvent<HTMLInputElement>) => {
              await updateComment(
                PackageCommentType.Hour,
                e.target.value,
                false,
              );
            }}
            onBlur={async (e: FocusEvent<HTMLInputElement>) => {
              setIsSubmitting(true);
              await updateComment(
                PackageCommentType.Hour,
                e.target.value,
                true,
              );
              setIsSubmitting(false);
            }}
            value={commentValue}
          />
        </Grid>
      </Grid>
      {!readOnly ? (
        <LabourHourImport
          loading={loading}
          project={project}
          userOrganisation={userOrganisation}
          contractorPackageId={contractorPackageId}
          existingLabourHours={labourHours}
          reportId={reportId}
          step={step}
          open={isOpen}
          setIsOpen={setIsOpen}
          setStep={setStep}
          upsertLabourHours={upsertLabourHours}
        />
      ) : null}
    </>
  );
};
