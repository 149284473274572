import * as ExcelJs from "exceljs";
import { LabourHourTargetAnalyticsLineItem } from "social-pro-common/interfaces/analytics";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";
import {
  createDefaultLabourHour,
  LabourHourLineItem,
} from "social-pro-common/interfaces/labourHour";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import {
  labourHourCommitmentTypeToString,
  SocialCommitmentTypeHour,
} from "social-pro-common/interfaces/packageLabourHourCommitment";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

import { getEmployeeFactors } from "../employeSocialFactors";

interface XlsxLabourHourResult {
  labourHours: LabourHourLineItem[];
  errorMessage?: string;
}

export const readXlsxFileToLabourHours = async (
  project: ProjectLineItem,
  reportId: string,
  contractorPackageId: string,
  organisation: OrganisationLineItem,
  file: File,
  searchAssignedEmployeesByNameOrCode: (
    projectId: string,
    contractorPackageId: string,
    employeeIds: string[],
  ) => Promise<EmployeeLineItem[]>,
): Promise<XlsxLabourHourResult> => {
  const HEADING_PROJECT_CODE_ROW = 8;
  const HEADING_PROJECT_NAME_ROW = 9;
  const DATA_START_ROW = 10;
  const DATA_COLUMN_OFFSET = 4;

  let errorMessage: string;
  return new Promise((resolve, reject) => {
    const wb = new ExcelJs.Workbook();
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        const data = new Uint8Array(reader.result as ArrayBuffer);
        wb.xlsx.load(data).then(async (workbook: ExcelJs.Workbook) => {
          const labourHours = [] as LabourHourLineItem[];
          const sheets = workbook.worksheets;
          for (const sheet of sheets) {
            const index = sheet.getRow(1).getCell(3).value;
            if (index == 2 || index == 3) {
              const columnHeaderPreRow = sheet.getRow(HEADING_PROJECT_CODE_ROW)
                .values as ExcelJs.CellValue[];
              if (columnHeaderPreRow) {
                const projectCodes = columnHeaderPreRow
                  .slice(4)
                  .map((v: ExcelJs.CellValue) => v?.toString().trim());
                const columnHeaderRow = sheet.getRow(HEADING_PROJECT_NAME_ROW)
                  .values as ExcelJs.CellValue[];
                if (columnHeaderRow) {
                  const projectNames = columnHeaderRow
                    .slice(4)
                    .map((v: ExcelJs.CellValue) => v?.toString());
                  const projectNameIndex = projectNames.indexOf(
                    projectNames?.find(
                      (v: any) =>
                        v.trim().toLowerCase() ===
                        project.projectName.trim().toLowerCase(),
                    ),
                  );
                  const projectCodeIndex = projectCodes.indexOf(
                    projectCodes?.find(
                      (v: any) =>
                        v.trim().toLowerCase() ===
                        project.projectCode.trim().toLowerCase(),
                    ),
                  );

                  if (projectNameIndex === -1 || projectCodeIndex === -1) {
                    errorMessage =
                      "Could not find matching project name or code";
                  } else if (projectNameIndex !== projectCodeIndex) {
                    errorMessage =
                      "Project name and project code in wrong columns";
                  } else {
                    const employeeHours: {
                      [key: string]: number;
                    } = {};

                    const idMap = new Map();
                    sheet.eachRow((row: ExcelJs.Row, rowIndex: number) => {
                      if (rowIndex >= DATA_START_ROW) {
                        const values = row.values as ExcelJs.CellValue[];
                        let employeeCode = values[2] as
                          | string
                          | number
                          | undefined;
                        if (employeeCode && typeof employeeCode !== "string") {
                          employeeCode = employeeCode.toString();
                        }
                        if (
                          employeeCode &&
                          ((typeof employeeCode === "string" &&
                            employeeCode.length > 0) ||
                            typeof employeeCode === "number")
                        ) {
                          employeeCode = employeeCode.toString();
                          const hoursWorked = values[
                            DATA_COLUMN_OFFSET + projectCodeIndex
                          ] as ExcelJs.CellFormulaValue;
                          const hoursValue = hoursWorked?.result || hoursWorked;
                          if (hoursValue && typeof hoursValue === "number") {
                            const parsedValue = Math.floor(
                              parseFloat(hoursValue.toString() || "0"),
                            );
                            const matchingLabourHour = labourHours.find(
                              (lh) => lh.employeeId === employeeCode,
                            );
                            if (matchingLabourHour) {
                              matchingLabourHour.amountOfHoursWorked +=
                                parsedValue;
                            } else {
                              if (!idMap.has(employeeCode)) {
                                idMap.set(employeeCode, rowIndex);
                                employeeHours[employeeCode] = parsedValue;
                              } else {
                                // reject(
                                //   `Duplicate employee codes ${employeeCode} at ${rowIndex} and ${idMap.get(employeeCode)}.`,
                                // );
                              }
                            }
                          }
                        }
                      }
                    });
                    const employeeIds = Object.keys(employeeHours);

                    const employeesToSearch: string[][] = [];
                    while (employeeIds.length > 0)
                      employeesToSearch.push(employeeIds.splice(0, 50));

                    const employees: EmployeeLineItem[] = [];
                    for (const employee of employeesToSearch) {
                      const foundEmployees =
                        await searchAssignedEmployeesByNameOrCode(
                          project.id,
                          contractorPackageId,
                          employee,
                        );
                      employees.push(...foundEmployees);
                    }

                    for (const employeeCode of Object.keys(employeeHours)) {
                      const matchingEmployee = employees.find(
                        (e) => e.employeeName === employeeCode,
                      );
                      if (!matchingEmployee) {
                        errorMessage =
                          (errorMessage || "") +
                          `\nCould not find employee - ${employeeCode}, are they assigned to the project?`;
                      }
                    }

                    for (const employee of employees) {
                      const hoursValue = employeeHours[employee.employeeName];
                      const exisitngLabourHour = labourHours.find(
                        (lh) => lh.employeeId === employee.id,
                      );
                      const employeeSocialFactors = getEmployeeFactors(
                        employee,
                        project,
                      );

                      if (exisitngLabourHour) {
                        exisitngLabourHour.amountOfHoursWorked = hoursValue;
                      } else {
                        const newLabourHour = createDefaultLabourHour(
                          project.id,
                          reportId,
                          contractorPackageId,
                          organisation,
                          employee,
                        );
                        newLabourHour.socialFactors = employeeSocialFactors.map(
                          (sf) => sf.id,
                        );
                        newLabourHour.amountOfHoursWorked = hoursValue;
                        labourHours.push(newLabourHour);
                      }
                    }
                  }
                } else {
                  errorMessage = "Could not parse the document";
                }
              }
            }
          }
          resolve({ errorMessage, labourHours });
        });
      } else {
        reject("Error reading file");
      }
    };
    reader.readAsArrayBuffer(file);
  });
};

export const writeXlsxLabourHourTargets = async (
  labourHours: LabourHourTargetAnalyticsLineItem[],
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    (async () => {
      try {
        const workbook = new ExcelJs.Workbook();
        const workSheet = workbook.addWorksheet("Labour Hours");
        workSheet.columns = [
          "Type",
          "Target (Hours)",
          "To Date (Hours)",
          "Reported (Hours)",
          "Progress",
        ];
        workSheet.addRow([
          "Type",
          "Target (Hours)",
          "To Date (Hours)",
          "Reported (Hours)",
          "Progress",
        ]);
        for (const labourHour of labourHours) {
          workSheet.addRow([
            labourHour.targetDescription ||
              labourHourCommitmentTypeToString(
                labourHour.targetType as SocialCommitmentTypeHour,
              ),
            formatDecimalPlaces(labourHour.targetValue),
            formatDecimalPlaces(labourHour.totalReportedValue),
            formatDecimalPlaces(labourHour.monthReportedValue),
            `${formatDecimalPlaces(labourHour.progress)}%`,
          ]);
        }

        const data = await workbook.xlsx.writeBuffer();
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `Social-Pro-Labour-Hour-Targets.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
        resolve(true);
      } catch (error) {
        reject(error);
      }
    })();
  });
};
