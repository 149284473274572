import { useAuthContext } from "@hooks/context/useAuthContext";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { useSocialProAdminOrganisation } from "@hooks/crud/socialProAdmin/useSocialProAdminOrganisation";
import { useSocialProAdminProfile } from "@hooks/crud/socialProAdmin/useSocialProAdminProfile";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Grid, Stack } from "@mui/material";
import { CustomButton } from "@stories/atoms/CustomButton/CustomButton";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";
import { ProfileTable } from "@stories/molecules/ProfileTable/ProfileTable";
import { SearchInput } from "@stories/molecules/SearchInput/SearchInput";
import { OrganisationTable } from "@stories/organisms/Tables/OrganisationTable";
import { useState } from "react";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";

export const SocialProAdmin = (): JSX.Element => {
  const { userOrganisation } = useProfileContext();
  const { isAuthLoading, userProfile } = useAuthContext();

  const [page, setPage] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [searchValue, setSearchValue] = useState<string>("");

  const [selectedOrganisation, setSelectedOrganisation] =
    useState<OrganisationLineItem>();

  const handleSearchChange = () => {
    setSearchValue(inputValue.length > 0 ? inputValue : "");
  };
  const {
    isSocialProAdminLoading,
    joinOrganisation,
    organisationCount,
    organisations,
    updateMaxProjectCount,
    updateOrganisationContractor,
  } = useSocialProAdminOrganisation(searchValue, page);

  const {
    changePassword,
    createProfile,
    deleteProfile,
    isSocialProAdminLoadingProfile,
    profiles,
    updateProfile,
  } = useSocialProAdminProfile(selectedOrganisation?.id);

  const handleClickOrganisation = (organisation: OrganisationLineItem) => {
    setSelectedOrganisation(organisation);
  };

  const handleBack = () => {
    setSelectedOrganisation(undefined);
  };

  const loading =
    isSocialProAdminLoading || isSocialProAdminLoadingProfile || isAuthLoading;
  return (
    <>
      <Grid
        container
        sx={{
          margin: "0",
          marginBottom: "0",
          width: "100%",
        }}
      >
        <PageHeader
          loading={loading}
          title="Social Pro Admin"
          subTitle={"Manage Subcontractors"}
        />
        <Grid
          container
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            padding: "20px 40px 10px",
          }}
        >
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              padding: "0 !important",
              textAlign: "right",
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              alignContent={"end"}
              justifyContent={selectedOrganisation ? "space-between" : "end"}
            >
              {selectedOrganisation && (
                <CustomButton
                  data-test-id="back-button"
                  loading={loading}
                  variant="outlined"
                  startIcon={<ChevronLeftIcon />}
                  onClick={handleBack}
                >
                  Back
                </CustomButton>
              )}
              <SearchInput
                loading={loading}
                inputValue={inputValue}
                setInputValue={setInputValue}
                handleSearchChange={handleSearchChange}
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            margin: "0 40px",
            overflow: "hidden",
            padding: "0px 0 !important",
          }}
          className="contract-table"
        >
          {selectedOrganisation && userProfile ? (
            <ProfileTable
              loading={false}
              profiles={profiles}
              handleChangePassword={async (
                profile: ProfileLineItem,
                newPassword: string,
              ): Promise<void> => {
                await changePassword(profile, newPassword);
              }}
              handleDeleteUser={async (
                profile: ProfileLineItem,
              ): Promise<void> => {
                await deleteProfile(profile);
              }}
              handleCreateUser={async (
                profile: ProfileLineItem,
              ): Promise<void> => {
                await createProfile(profile);
              }}
              handleUpdateUser={async (
                profile: ProfileLineItem,
              ): Promise<void> => {
                await updateProfile(profile);
              }}
              organisation={selectedOrganisation}
              userProfile={userProfile}
            />
          ) : (
            <OrganisationTable
              loading={loading}
              userOrganisation={userOrganisation}
              organisations={organisations}
              organisationCount={organisationCount}
              setPage={setPage}
              handleJoinOrganisation={joinOrganisation}
              handleUpdateOrganisationContractor={updateOrganisationContractor}
              handleUpdateMaxProjectCount={updateMaxProjectCount}
              handleClickOrganisation={handleClickOrganisation}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
