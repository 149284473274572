import { getApiData } from "@hooks/utils/api";
import { getImageUrl } from "@hooks/utils/useUpload";
import { catchSentryError } from "@utils/sentry";
import { useCallback, useEffect, useState } from "react";
import { decodeOrganisationListModel } from "social-pro-common/decoders/socialProAdmin";
import { OrganisationListModel } from "social-pro-common/entities/socialProAdmin";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";

export const useSocialProAdminOrganisation = (query?: string, page = 0) => {
  const [organisations, setOrganisations] = useState<OrganisationLineItem[]>(
    [],
  );
  const [organisationCount, setOrganisationCount] = useState<number>(0);
  const [isSocialProAdminLoading, setIsSocialProAdminLoading] =
    useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const listOrganisations = useCallback(
    async (query?: string, page = 0) => {
      try {
        setIsSocialProAdminLoading(true);
        setOrganisations([]);
        const organisationResult = await getApiData(
          "listAllOrganisations",
          "socialProAdmin",
          "",
          { page, query },
        );

        const decodedOrganisations = await decodeOrganisationListModel(
          organisationResult.data as OrganisationListModel,
          getImageUrl,
        );
        setOrganisations(decodedOrganisations.organisations);
        setOrganisationCount(decodedOrganisations.totalOrganisations);
      } catch (e) {
        catchSentryError(e);
        setError("Could not list organisations");
      } finally {
        setIsSocialProAdminLoading(false);
      }
    },
    [
      getApiData,
      decodeOrganisationListModel,
      setOrganisations,
      setOrganisationCount,
      setIsSocialProAdminLoading,
      setError,
      getImageUrl,
    ],
  );

  const joinOrganisation = useCallback(
    async (organisation: OrganisationLineItem) => {
      try {
        setIsSocialProAdminLoading(true);
        await getApiData("joinOrganisation", "socialProAdmin", organisation.id);
        window.location.reload();
      } catch (e) {
        catchSentryError(e);
        setError("Could not join organisation");
      } finally {
        setIsSocialProAdminLoading(false);
      }
    },
    [getApiData, setIsSocialProAdminLoading, catchSentryError, setError],
  );

  const updateMaxProjectCount = useCallback(
    async (organisation: OrganisationLineItem, maxActiveProjects: number) => {
      try {
        setIsSocialProAdminLoading(true);
        await getApiData(
          "updateMaxProjectCount",
          "socialProAdmin",
          organisation.id,
          { maxActiveProjects },
        );
      } catch (e) {
        catchSentryError(e);
        setError("Could not update max project count");
      } finally {
        setIsSocialProAdminLoading(false);
      }
    },
    [getApiData, setIsSocialProAdminLoading, catchSentryError, setError],
  );

  const updateOrganisationContractor = useCallback(
    async (organisation: OrganisationLineItem, isContractor: boolean) => {
      try {
        setIsSocialProAdminLoading(true);
        await getApiData(
          "updateOrganisationContractor",
          "socialProAdmin",
          organisation.id,
          { isContractor: isContractor ? "true" : "false" },
        );
      } catch (e) {
        catchSentryError(e);
        setError("Could not update organisation contractor status");
      } finally {
        setIsSocialProAdminLoading(false);
      }
    },
    [getApiData, setIsSocialProAdminLoading, catchSentryError, setError],
  );

  useEffect(() => {
    listOrganisations(query, page);
  }, [listOrganisations, query, page]);

  return {
    error,
    isSocialProAdminLoading,
    joinOrganisation,
    organisationCount,
    organisations,
    updateMaxProjectCount,
    updateOrganisationContractor,
  };
};
