import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  useTheme,
  Zoom,
} from "@mui/material";
import { useState } from "react";
import {
  ProfileLineItem,
  profileTypeToString,
} from "social-pro-common/interfaces/profile";

import ChangePasswordModal from "../ChangePasswordModal/ChangePasswordModal";
import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";

interface ProfileTableRowProps {
  userProfile: ProfileLineItem;
  profile: ProfileLineItem;
  handleChangePassword: (profile: ProfileLineItem, newPassword: string) => void;
  handleDeleteUser: (profile: ProfileLineItem) => void;
  handleEditUser: (profile: ProfileLineItem) => void;
}

export const ProfileTableRow = ({
  handleChangePassword,
  handleDeleteUser,
  handleEditUser,
  profile,
  userProfile,
}: ProfileTableRowProps) => {
  const theme = useTheme();

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  return (
    <>
      <TableRow key={profile.id}>
        <TableCell align="left" sx={{ width: "20%" }}>
          {profile.name}
        </TableCell>
        <TableCell align="left" sx={{ width: "25%" }}>
          {profile.email}
        </TableCell>
        <TableCell align="left" sx={{ width: "15%" }}>
          {profileTypeToString(profile.isAdmin)}
        </TableCell>
        <TableCell align="center" sx={{ width: "25%" }}>
          <ChangePasswordModal
            profile={profile}
            handleChangePassword={handleChangePassword}
          />
        </TableCell>
        <TableCell align="center" sx={{ width: "15%" }}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Tooltip TransitionComponent={Zoom} placement="top" title="Edit">
              <IconButton
                color="primary"
                disabled={userProfile?.id === profile.id}
                sx={{
                  "&:hover ": {
                    background: theme.palette.grey[200],
                  },
                }}
                size="large"
                onClick={() => handleEditUser(profile)}
              >
                <EditIcon sx={{ fontSize: "1.1rem" }} />
              </IconButton>
            </Tooltip>
            <Tooltip TransitionComponent={Zoom} placement="top" title="Delete">
              <IconButton
                disabled={userProfile?.id === profile.id}
                color="error"
                sx={{
                  "&:hover ": {
                    background: theme.palette.grey[200],
                  },
                }}
                size="large"
                onClick={() => setOpenConfirmDialog(true)}
              >
                <DeleteIcon
                  sx={{
                    fontSize: "1.1rem",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        message={"Are you sure you want to delete this user?"}
        open={openConfirmDialog}
        title={"Delete User"}
        intent={"error"}
        buttonText={"Delete"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={() => handleDeleteUser(profile)}
      />
    </>
  );
};
