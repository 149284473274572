import { useSearchEmployee } from "@hooks/crud/employee/useSearchEmployee";
import { useProject } from "@hooks/crud/project/useProject";
import useProjectEmployeeAssignment from "@hooks/crud/projectEmployee/useProjectEmployeeAssignment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Link,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Iconify from "@stories/atoms/Iconify/Iconify";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { validatePostCode } from "@utils/location";
import { readXlsxFileToEmployeeForDamstra } from "@utils/xlsx/damstra";
import { readXlsxFileToEmployee } from "@utils/xlsx/employee";
import { useRef, useState } from "react";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";
import { ContractorEmployeeProjectLineItem } from "social-pro-common/interfaces/contractorEmployeeProject";
import { IntegrationType } from "social-pro-common/interfaces/integration";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import { ProjectDetailLineItem } from "social-pro-common/interfaces/project";

import { EmployeeIdentityDocsFormRow } from "../EmployeeSupportingDocsFormRow/EmployeeIdentityDocsFormRow";

export enum ImportStep {
  Init = "Init",
  Parse = "Parse",
  Upload = "Upload",
  Validating = "Validating",
  SelectAssignment = "SelectAssignment",
  Assign = "Assign",
  AssignComplete = "AssignComplete",
}

interface EmployeeImportProps {
  open: boolean;
  step: ImportStep;
  userProfile?: ProfileLineItem;
  userOrganisation?: OrganisationLineItem;
  handleCloseImportModal: () => void;
  setStep: (step: ImportStep) => void;
  upsertEmployees: (employees: EmployeeLineItem[]) => Promise<void>;
  handleReloadEmployees: () => void;
}

export default function EmployeeImport({
  handleCloseImportModal,
  handleReloadEmployees,
  open,
  setStep,
  step,
  upsertEmployees,
  userOrganisation,
  userProfile,
}: EmployeeImportProps) {
  const { isProjectLoading, projects } = useProject(userProfile);
  const { searchEmployeesByNameOrCode } = useSearchEmployee();
  const { updateEmployeeAssignment } = useProjectEmployeeAssignment();
  const [uploadMessage, setUploadMessage] = useState("Uploading...");
  const [selectedEmployees, setSelectedEmployees] = useState<
    EmployeeLineItem[]
  >([]);

  const [selectedProjectsToAssign, setSelectedProjectsToAssign] = useState<
    ProjectDetailLineItem[]
  >([]);
  const fileInput = useRef<HTMLInputElement | null>(null);

  const onFileInput = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const onUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    isDamstra = false,
  ) => {
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      if (userOrganisation) {
        setStep(ImportStep.Parse);
        const file = e.currentTarget.files[0];
        try {
          const xlsEmployeeReponse = isDamstra
            ? await readXlsxFileToEmployeeForDamstra(userOrganisation, file)
            : await readXlsxFileToEmployee(userOrganisation, file);

          if (xlsEmployeeReponse.errorMessage) {
            alert(xlsEmployeeReponse.errorMessage);
            handleCloseImportModal();
          } else {
            setStep(ImportStep.Validating);
            const totalEmployees = xlsEmployeeReponse.employees.length;
            const employeeData = [];

            const employeeIds = xlsEmployeeReponse.employees.map(
              (e) => e.employeeName,
            );

            const employeesToSearch: string[][] = [];
            while (employeeIds.length > 0)
              employeesToSearch.push(employeeIds.splice(0, 25));

            let rowCount = 1;
            const duplicateEmployees: EmployeeLineItem[] = [];
            for (const employee of employeesToSearch) {
              setUploadMessage(
                `Deduping ${rowCount}/${employeesToSearch.length} employees...`,
              );
              const foundEmployees =
                await searchEmployeesByNameOrCode(employee);

              if (foundEmployees.length > 0) {
                duplicateEmployees.push(...foundEmployees);
              }
            }

            const finalEmployees = xlsEmployeeReponse.employees.map((e) => {
              const duplicate = duplicateEmployees.find(
                (d) => d.employeeName === e.employeeName,
              );
              if (duplicate) {
                e.id = duplicate.id;
              }
              return e;
            });

            if (finalEmployees.length === 0) {
              const errorMessage = `No employees to add or update`;
              throw new Error(errorMessage);
            } else {
              rowCount = 1;
              for (const employee of finalEmployees) {
                setUploadMessage(
                  `Validating ${rowCount}/${totalEmployees} employees...`,
                );

                //TODO: Fix this to handle it more gracefully
                if (employee.postCode === "3001") {
                  throw new Error(
                    `Could not validate postcode for row ${rowCount}: ${employee.employeeName}`,
                  );
                }
                if (employee.country && employee.postCode) {
                  const validPostCode = await validatePostCode(
                    employee.country,
                    employee.postCode,
                  );
                  if (!validPostCode) {
                    throw new Error(
                      `Could not validate postcode for row ${rowCount}: ${employee.employeeName}`,
                    );
                  }
                }

                rowCount += 1;
              }

              while (finalEmployees.length > 0)
                employeeData.push(finalEmployees.splice(0, 50));

              setStep(ImportStep.Upload);

              let count = 0;
              for (const employee of employeeData) {
                count += employee.length;
                setUploadMessage(
                  `Uploading ${count}/${totalEmployees} employees...`,
                );
                await upsertEmployees(employee);
              }
              setSelectedEmployees(employeeData.flat());
            }
          }
          if (projects.length == 0 || EmployeeIdentityDocsFormRow.length == 0) {
            handleCloseImportModal();
            setStep(ImportStep.Init);
            handleReloadEmployees();
          } else {
            setStep(ImportStep.SelectAssignment);
          }
        } catch (err: any) {
          alert(err);
          handleCloseImportModal();
        }
      }
    }
  };

  const assignEmployeesToProject = async () => {
    setStep(ImportStep.Assign);
    for (const selectedProject of selectedProjectsToAssign) {
      const selectedEmployeesToAssign = selectedEmployees.map((employee) => {
        return {
          assigned: true,
          contractorEmployeeId: employee.id,
          contractorPackageId: selectedProject.contractorPackageId,
          id: `${employee.id}-${selectedProject.contractorPackageId}`,
          projectId: selectedProject.id,
        } as ContractorEmployeeProjectLineItem;
      });
      const employeesToAssign: ContractorEmployeeProjectLineItem[][] = [];
      while (selectedEmployeesToAssign.length > 0)
        employeesToAssign.push(selectedEmployeesToAssign.splice(0, 50));

      let rowCount = 1;
      for (const employeeBatch of employeesToAssign) {
        setUploadMessage(
          `Assigning ${rowCount}/${employeesToAssign.length} employees to ${selectedProject.projectName}`,
        );
        await updateEmployeeAssignment(employeeBatch);
        rowCount += 1;
      }
    }
    handleReloadEmployees();
    handleCloseImportModal();
    setStep(ImportStep.Init);
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const loading = isProjectLoading;
  const importEmployeeStep = (() => {
    switch (step) {
      default:
      case ImportStep.Init:
        return (
          <Grid
            item
            md={12}
            sx={{
              display: "flex",
              gap: "30px",
              justifyContent: "center",
              margin: "0",
              padding: "50px 30px 50px",
            }}
          >
            <IconButton
              className="closeBtn"
              aria-label="close"
              onClick={() => {
                handleCloseImportModal();
              }}
              disabled={loading}
              sx={{
                "&:hover": {
                  background: "#143E7C",
                },
                background: "#000",
                color: "#fff",
                position: "absolute",
                right: 14,
                top: 11,
              }}
            >
              <CloseIcon />
            </IconButton>
            <StyledButton
              loading={false}
              variant="contained"
              className="blackBtn grey-outline-btn"
              startIcon={<Iconify icon="mdi:microsoft-excel" />}
              disabled={loading}
            >
              <Link
                href={
                  process.env.PUBLIC_URL + "/SocialProTemplateEmployee.xlsx"
                }
                sx={{
                  color: "#000 !important",
                  textDecoration: "none !important",
                }}
              >
                Download Template
              </Link>
            </StyledButton>
            <StyledButton
              loading={false}
              onClick={onFileInput}
              variant="contained"
              className="blackBtn"
              startIcon={<CloudUploadIcon />}
              disabled={loading}
            >
              <input
                hidden
                ref={fileInput}
                accept="*/xlsx"
                type="file"
                onChange={onUpload}
              />
              {"Upload"}
            </StyledButton>
            {userOrganisation?.integrations?.find(
              (i) =>
                i.integrationType === IntegrationType.DamstraImport &&
                i.enabled,
            ) ? (
              <StyledButton
                loading={false}
                onClick={onFileInput}
                variant="contained"
                className="blackBtn"
                startIcon={<CloudUploadIcon />}
                disabled={loading}
              >
                <input
                  hidden
                  ref={fileInput}
                  accept="*/xlsx"
                  type="file"
                  onChange={(e) => onUpload(e, true)}
                />
                {"Damstra Upload"}
              </StyledButton>
            ) : null}
          </Grid>
        );

      case ImportStep.Parse:
        return (
          <>
            <Box
              sx={{
                margin: "auto !important",
                padding: "10%",
              }}
            >
              <CircularProgress />
            </Box>
            <Typography align="center">Parsing...</Typography>
          </>
        );
      case ImportStep.Validating:
      case ImportStep.Assign:
      case ImportStep.Upload:
        return (
          <>
            <Box
              sx={{
                margin: "auto !important",
                padding: "10%",
              }}
            >
              <CircularProgress />
            </Box>
            <Typography align="center">{uploadMessage}</Typography>
          </>
        );
      case ImportStep.SelectAssignment:
        return (
          <>
            <Typography>Select projects to assign new employees</Typography>
            <FormControl variant="outlined" sx={{ width: "100%" }}>
              {loading ? (
                <Skeleton animation="wave" variant="rounded" width={"100%"}>
                  <TextField
                    label="DEFAULT TEXT FIELD CONTENT - LOADING"
                    margin="dense"
                    fullWidth
                  />
                </Skeleton>
              ) : (
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  fullWidth
                  id="projectsToAssign"
                  options={projects}
                  filterSelectedOptions
                  getOptionLabel={(option) => option.projectName}
                  value={selectedProjectsToAssign}
                  onChange={(
                    event: any,
                    newValue: ProjectDetailLineItem[] | null,
                  ) => {
                    setSelectedProjectsToAssign(newValue || []);
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.projectName}
                    </li>
                  )}
                  renderTags={(value) => {
                    // Join all selected values into a single string with commas
                    const text = value.map((v) => v.projectName).join(", ");

                    // Check if the text length exceeds 20 characters
                    if (text.length > 20) {
                      // Truncate and add ellipsis
                      return `${text.substring(0, 17)}... ${
                        value.length > 1 ? `+${value.length - 1}` : ""
                      }`;
                    }

                    // If under 20 characters, return the full text
                    return text;
                  }}
                  renderInput={(params) => (
                    <TextField
                      data-test-id="projectsToAssign"
                      {...params}
                      name="projectsToAssign"
                      label="Projects To Assign"
                      fullWidth
                    />
                  )}
                />
              )}
            </FormControl>
            <Stack direction="row" spacing={2}>
              <StyledButton
                loading={false}
                onClick={() => {
                  handleCloseImportModal();
                  setStep(ImportStep.Init);
                }}
                variant="contained"
                className="blackBtn grey-outline-btn"
                disabled={loading}
              >
                {"Close"}
              </StyledButton>
              <StyledButton
                loading={false}
                onClick={assignEmployeesToProject}
                variant="contained"
                className="blackBtn"
                startIcon={<AddCircleIcon />}
                disabled={loading}
              >
                {"Assign"}
              </StyledButton>
            </Stack>
          </>
        );
      case ImportStep.AssignComplete:
        return (
          <>
            <Typography>Success, the have been assigned.</Typography>
            <Stack direction="row" spacing={2}>
              <StyledButton
                loading={false}
                onClick={() => {
                  handleCloseImportModal();
                  setStep(ImportStep.Init);
                }}
                variant="contained"
                className="blackBtn grey-outline-btn"
                disabled={loading}
              >
                {"Close"}
              </StyledButton>
            </Stack>
          </>
        );
    }
  })();

  return (
    <Dialog maxWidth="md" open={open} className="bgBlueOverlay">
      <DialogTitle>Import Employees</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Stack spacing={2}>{importEmployeeStep}</Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
