import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Grid, Stack, Table, TableBody } from "@mui/material";
import { CustomButton } from "@stories/atoms/CustomButton/CustomButton";
import ListTableHead from "@stories/molecules/ListTable/ListTableHead";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import UserModal from "@stories/organisms/UserModal/UserModal";
import { useState } from "react";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import { TableHeaderCell } from "social-pro-common/interfaces/tables";

import { ProfileTableRow } from "./ProfileTableRow";
import { ProfileTableRowSkeleton } from "./ProfileTableRowSkeleton";

interface ProfileTableProps {
  loading: boolean;
  profiles: ProfileLineItem[];
  organisation: OrganisationLineItem;
  handleCreateUser: (profile: ProfileLineItem) => void;
  handleUpdateUser: (profile: ProfileLineItem) => void;
  handleDeleteUser: (profile: ProfileLineItem) => void;
  handleChangePassword: (profile: ProfileLineItem, newPassword: string) => void;
  userProfile: ProfileLineItem;
}

export const ProfileTable = ({
  handleChangePassword,
  handleCreateUser,
  handleDeleteUser,
  handleUpdateUser,
  loading,
  organisation,
  profiles,
  userProfile,
}: ProfileTableProps) => {
  const [selectedProfile, setSelectedProfile] = useState<ProfileLineItem>();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setSelectedProfile(undefined);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleEditUser = (profile: ProfileLineItem) => {
    setSelectedProfile(profile);
    handleOpen();
  };

  const TABLE_HEAD: TableHeaderCell[] = [
    { align: "left", id: "employee-name", label: "Name" },
    { align: "left", id: "employee-email", label: "Email" },
    { align: "left", id: "employee-admin", label: "Account Type" },
    { id: "" },
  ];
  return (
    <>
      {organisation ? (
        <UserModal
          open={open}
          loading={loading}
          organisation={organisation}
          profiles={profiles}
          handleClose={handleClose}
          createUser={handleCreateUser}
          updateUser={handleUpdateUser}
          existingUser={selectedProfile}
        />
      ) : null}
      <Grid item>
        <Stack direction="row" spacing={2} justifyContent={"end"}>
          <CustomButton
            data-test-id="add-user-button"
            loading={loading}
            variant="contained"
            startIcon={<PersonAddIcon />}
            onClick={handleOpen}
          >
            Add User
          </CustomButton>
        </Stack>
        {loading ? (
          <Table>
            <ListTableHead loading={loading} headLabel={TABLE_HEAD} />
            <TableBody>
              {Array.from({ length: 10 }, (_, index) => (
                <ProfileTableRowSkeleton key={index} />
              ))}
            </TableBody>
          </Table>
        ) : (
          <Table data-test-id="users-table">
            <ListTableHead loading={loading} headLabel={TABLE_HEAD} />
            <TableBody>
              {profiles.length > 0
                ? profiles.map((row) => {
                    return (
                      <ProfileTableRow
                        key={row.id}
                        userProfile={userProfile}
                        handleEditUser={handleEditUser}
                        handleDeleteUser={handleDeleteUser}
                        profile={row}
                        handleChangePassword={handleChangePassword}
                      />
                    );
                  })
                : null}

              {profiles.length === 0 ? (
                <NoRows colSpan={TABLE_HEAD.length} title={"Users"} />
              ) : null}
            </TableBody>
          </Table>
        )}
      </Grid>
    </>
  );
};
