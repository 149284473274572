import {
  getApiData,
  postApiData,
  postBatchApiData,
  searchApiData,
} from "@hooks/utils/api";
import { catchSentryError } from "@utils/sentry";
import { useCallback, useState, useEffect } from "react";
import {
  decodeContractorEmployeeListModel,
  decodeEmployee,
  encodeEmployee,
} from "social-pro-common/decoders/contractorEmployee";
import { encodeContractorEmployeeProject } from "social-pro-common/decoders/contractorEmployeeProject";
import { decodeEmployeeHoursToDate } from "social-pro-common/decoders/labourHour";
import {
  ContractorEmployee,
  ContractorEmployeeListModel,
} from "social-pro-common/entities/contractorEmployee";
import { EmployeeHoursToDate } from "social-pro-common/entities/labourHour";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";
import { ContractorEmployeeProjectLineItem } from "social-pro-common/interfaces/contractorEmployeeProject";
import { EmployeeHoursToDateLineItem } from "social-pro-common/interfaces/labourHour";

export const useProjectAssignedEmployee = (
  organisationId?: string,
  projectId?: string,
  contractorPackageId?: string,
  standardEmployment?: boolean,
  query?: string,
  page = 0,
  reportId?: string,
  archived = false,
) => {
  const [employeeCount, setEmployeeCount] = useState<number>(0);
  const [employees, setEmployees] = useState<EmployeeLineItem[]>([]);
  const [labourHoursForEmployee, setLabourHoursForEmployee] = useState<
    EmployeeHoursToDateLineItem[]
  >([]);
  const [isEmployeeLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const listAssignedEmployees = useCallback(
    async (projectId: string, contractorPackageId: string) => {
      try {
        setIsLoading(true);
        const employeeResult = await getApiData(
          "listAssignedEmployees",
          "projectEmployee",
          projectId,
          {
            contractorPackageId,
            organisationId,
            page: page.toString(),
            query,
            reportId,
            standardEmployment: standardEmployment ? "true" : undefined,
          },
        );
        const decodedEmployees = decodeContractorEmployeeListModel(
          employeeResult.data as ContractorEmployeeListModel,
        );

        if (decodedEmployees.totalEmployees > 0) {
          const labourHourResult = await searchApiData(
            "listLabourHoursForEmployeeToDate",
            "labourHour",
            decodedEmployees.employees.map((p) => p.id),
            {
              contractorPackageId,
              projectId,
              reportId,
            },
          );
          const labourHours = labourHourResult.data.map((p) =>
            decodeEmployeeHoursToDate(p as EmployeeHoursToDate),
          );
          setLabourHoursForEmployee(labourHours);
        }
        setEmployeeCount(decodedEmployees.totalEmployees);
        setEmployees(decodedEmployees.employees);
      } catch (e) {
        catchSentryError(e);
        setError("Could not list employees");
      } finally {
        setIsLoading(false);
      }
    },
    [organisationId, page, query, reportId, standardEmployment],
  );

  const createEmployee = useCallback(
    async (
      employee: EmployeeLineItem,
      projectId: string,
    ): Promise<EmployeeLineItem> => {
      try {
        setIsLoading(true);
        const encodedEmployee = encodeEmployee(employee);
        const upsertedEmployee = await postApiData(
          "createContractorEmployee",
          "contractorEmployee",
          encodedEmployee,
        );

        const employeeAssignment = {
          assigned: true,
          contractorEmployeeId: employee.id,
          contractorPackageId: contractorPackageId,
          id: `${employee.id}-${projectId}`,
          projectId: projectId,
        } as ContractorEmployeeProjectLineItem;
        const encodedAssignments = [
          encodeContractorEmployeeProject(employeeAssignment),
        ];
        await postBatchApiData(
          "upsertEmployeeProjectAssignment",
          "projectEmployee",
          encodedAssignments,
        );

        const decodedEmployee = decodeEmployee(
          upsertedEmployee.data as ContractorEmployee,
        );

        setEmployees([...employees, { ...decodedEmployee }]);
        setEmployeeCount(employeeCount + 1);
        return decodedEmployee;
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not list employees");
      } finally {
        setIsLoading(false);
      }
      return employee;
    },
    [employees, employeeCount],
  );

  const updateEmployee = useCallback(
    async (employee: EmployeeLineItem): Promise<void> => {
      try {
        setIsLoading(true);
        const encodedEmployee = encodeEmployee(employee);
        await postApiData(
          "updateContractorEmployee",
          "contractorEmployee",
          encodedEmployee,
        );
        setEmployees(
          employees
            .map((c) => {
              if (c.id === employee.id) {
                return employee;
              }
              return c;
            })
            .filter((c) => c.archived === archived),
        );
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not update employee");
      } finally {
        setIsLoading(false);
      }
    },
    [employees],
  );

  useEffect(() => {
    if (projectId && contractorPackageId) {
      listAssignedEmployees(projectId, contractorPackageId);
    }
  }, [
    listAssignedEmployees,
    organisationId,
    projectId,
    contractorPackageId,
    reportId,
  ]);
  console.log("Employees in hook", employees);

  return {
    createEmployee,
    employeeCount,
    employees,
    error,
    isEmployeeLoading,
    labourHoursForEmployee,
    listAssignedEmployees,
    updateEmployee,
  };
};
