import { Button, ButtonProps, Skeleton, styled } from "@mui/material";

const BaseButton = styled(Button)(
  ({ theme, variant }: { theme: any; variant?: string }) => ({
    "&.Mui-disabled": {
      backgroundColor: theme.palette.action.disabledBackground,
      color: theme.palette.action.disabled,
    },
    borderRadius: "6px",
    fontWeight: 500,
    height: "auto",
    padding: "8px 32px",
    textTransform: "none",
    ...(variant === "text" && {
      backgroundColor: "transparent",
      color: "black",
    }),
    ...(variant === "contained" && {
      "&:hover": {
        backgroundColor: "#203461",
      },
      backgroundColor: "black",
      color: "#fff",
    }),
    ...(variant === "outlined" && {
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
        border: "1px solid black",
      },
      border: "1px solid black",
      color: "black",
    }),
  }),
);

type CustomButtonProps = ButtonProps & {
  loading?: boolean;
  variant?: "text" | "contained" | "outlined";
};

export const CustomButton: React.FC<CustomButtonProps> = ({
  children,
  loading = false,
  variant = "contained",
  ...props
}) => {
  return loading ? (
    <Skeleton animation="wave" variant="rounded">
      <BaseButton {...props} variant={variant} disabled>
        {children}
      </BaseButton>
    </Skeleton>
  ) : (
    <BaseButton {...props} variant={variant}>
      {children}
    </BaseButton>
  );
};
