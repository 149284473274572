import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { CustomButton } from "@stories/atoms/CustomButton/CustomButton";
import React from "react";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";

const ChangePasswordDialog: React.FC<{
  profile: ProfileLineItem;
  handleChangePassword: (profile: ProfileLineItem, password: string) => void;
}> = ({ handleChangePassword, profile }) => {
  const [open, setOpen] = React.useState(false);
  const [password, setPassword] = React.useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = () => {
    handleClose();
    handleChangePassword(profile, password);
  };

  return (
    <>
      <CustomButton variant="outlined" onClick={handleOpen}>
        Change Password
      </CustomButton>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <Typography mb={3}>
            Enter new password for{" "}
            <Typography component="span" fontWeight="bold">
              {profile.email}
            </Typography>
          </Typography>
          <Stack spacing={2}>
            <TextField
              label="New Password"
              type="password"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <CustomButton
            onClick={handleClose}
            variant="outlined"
            id="confirm-dialog-button-cancel"
          >
            Cancel
          </CustomButton>
          <CustomButton
            disabled={!password.trim()}
            data-test-id="confirm-dialog-button-ok"
            onClick={handleSubmit}
          >
            Submit
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChangePasswordDialog;
