import EditIcon from "@mui/icons-material/Edit";
import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
  Box,
  Stack,
  Switch,
} from "@mui/material";
import { ForecastUnitType } from "social-pro-common/entities/forecastData";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { ForecastModelLineItem } from "social-pro-common/interfaces/forecastData";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

interface ForecastTableRowProps {
  loading: boolean;
  disabled: boolean;
  project?: ProjectLineItem;
  contractorPackage?: ContractorPackageLineItem;
  forecastModel: ForecastModelLineItem;
  editForecastData: (forecastModel: ForecastModelLineItem) => void;
  updateForecastModel: (forecastModel: ForecastModelLineItem) => void;
}

export const ForecastTableRow = ({
  contractorPackage,
  disabled,
  editForecastData,
  forecastModel,
  project,
  updateForecastModel,
}: ForecastTableRowProps) => {
  const units = forecastModel.units;

  if (!project) {
    return null;
  }
  const isPrimaryPackage = contractorPackage?.id === project?.id;
  const matchingCommitment =
    units === ForecastUnitType.Hour
      ? isPrimaryPackage
        ? project.commitmentsHours.find(
            (c) => c.id === forecastModel.projectLabourHourCommitmentId,
          )
        : contractorPackage?.commitmentsHours.find(
            (c) =>
              c.projectLabourHourCommitmentId ===
              forecastModel.projectLabourHourCommitmentId,
          )
      : isPrimaryPackage
        ? project.commitmentsSpend.find(
            (c) => c.id === forecastModel.projectSocialSpendCommitmentId,
          )
        : contractorPackage?.commitmentsSpend.find(
            (c) =>
              c.projectSocialSpendCommitmentId ===
              forecastModel.projectSocialSpendCommitmentId,
          );
  if (!matchingCommitment) {
    return null;
  }

  const targetTotal = matchingCommitment.targetValueRealised;
  const reportedTotal = matchingCommitment.achievedValue;
  const remainingTotal = Math.max(0, targetTotal - reportedTotal);
  const target = matchingCommitment?.targetValueRealised || targetTotal;

  const reported = forecastModel.data.reduce(
    (acc, d) => acc + (d.value || 0),
    0,
  );

  const remaining = Math.max(
    0,
    matchingCommitment ? target - reported : remainingTotal,
  );

  return (
    <>
      <TableRow key={forecastModel.id}>
        <TableCell>
          <span
            style={{
              background: forecastModel.colour,
              borderRadius: "50%",
              boxShadow: "none",
              display: "block",
              height: "12px",
              margin: "0 auto",
              width: "12px",
            }}
          />
        </TableCell>
        <TableCell align="left" scope="row" sx={{ width: "30%" }}>
          {forecastModel.modelName}
        </TableCell>
        <TableCell align="center" sx={{ width: "10%" }} className="center">
          <Typography align="center">
            {units == ForecastUnitType.Spend ? "$ " : ""}
            {formatDecimalPlaces(target)}
          </Typography>
        </TableCell>
        <TableCell align="center" sx={{ width: "10%" }} className="center">
          <Typography align="center">
            {units == ForecastUnitType.Spend ? "$ " : ""}
            {formatDecimalPlaces(reported)}
          </Typography>
        </TableCell>
        <TableCell align="center" sx={{ width: "10%" }} className="center">
          <Typography align="center">
            {units == ForecastUnitType.Spend ? "$ " : ""}
            {formatDecimalPlaces(remaining)}
          </Typography>
        </TableCell>
        <TableCell align="right" sx={{ width: "10%" }}>
          <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
            <Switch
              disabled={disabled}
              color="success"
              onChange={() => {
                updateForecastModel({
                  ...forecastModel,
                  active: !forecastModel.active,
                });
              }}
              checked={forecastModel.active}
            />

            <Box display="flex" alignItems="center">
              <Tooltip placement="top" title="Edit" TransitionComponent={Zoom}>
                <IconButton
                  color="primary"
                  size="large"
                  disabled={disabled}
                  onClick={() => editForecastData(forecastModel)}
                >
                  <EditIcon
                    sx={{
                      fontSize: "1.1rem",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
};
