import { getApiData, listApiData, postApiData } from "@hooks/utils/api";
import { catchSentryError } from "@utils/sentry";
import { useCallback, useEffect, useState } from "react";
import {
  decodeProfile,
  encodeProfile,
} from "social-pro-common/decoders/profile";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";

export const useSocialProAdminProfile = (organisationId?: string) => {
  const [profiles, setProfiles] = useState<ProfileLineItem[]>([]);
  const [isSocialProAdminLoadingProfile, setIsSocialProAdminLoadingProfile] =
    useState<boolean>(false); // THIS SHOULD BE TRUE UNTIL THE FIRST LOAD HAS SUCCESSFULLY COMPELTED
  const [error, setError] = useState<string | null>(null);

  const listProfiles = useCallback(
    async (_: string) => {
      try {
        setIsSocialProAdminLoadingProfile(true);
        setProfiles([]);
        // const profileResult = await listApiData(
        //   "listUsersForOrganisation",
        //   "socialProAdmin",
        //   organisationId,
        // );
        setProfiles([]);
      } catch (e) {
        catchSentryError(e);
        setError("Could not list organisations");
      } finally {
        setIsSocialProAdminLoadingProfile(false); //SET LOADING TO FALSE, ON FAIL AND SUCESS.
      }
    },
    [listApiData, setProfiles, setIsSocialProAdminLoadingProfile, setError],
  );

  const createProfile = useCallback(
    async (profile: ProfileLineItem): Promise<ProfileLineItem> => {
      try {
        setIsSocialProAdminLoadingProfile(true);
        const encodedProfile = encodeProfile(profile);
        // const upsertedProfile = await postApiData(
        //   "createProfileAdmin",
        //   "socialProAdmin",
        //   encodedProfile,
        // );
        const decodedProfile = decodeProfile(encodedProfile);
        setProfiles((prevProfiles) => [...prevProfiles, decodedProfile]);
        return decodedProfile;
      } catch (e: any) {
        setError("Could not create profile");
      } finally {
        setIsSocialProAdminLoadingProfile(false);
      }
      return profile;
    },
    [postApiData, setProfiles, setIsSocialProAdminLoadingProfile, setError],
  );

  const updateProfile = useCallback(
    async (profile: ProfileLineItem): Promise<ProfileLineItem> => {
      try {
        setIsSocialProAdminLoadingProfile(true);
        const encodedProfile = encodeProfile(profile);
        // const upsertedProfile = await postApiData(
        //   "updateProfileAdmin",
        //   "socialProAdmin",
        //   encodedProfile,
        // );
        const decodedProfile = decodeProfile(encodedProfile);
        setProfiles((prevProfiles) => [...prevProfiles, decodedProfile]);
        return decodedProfile;
      } catch (e: any) {
        setError("Could not create profile");
      } finally {
        setIsSocialProAdminLoadingProfile(false);
      }
      return profile;
    },
    [postApiData, setProfiles, setIsSocialProAdminLoadingProfile, setError],
  );

  const deleteProfile = useCallback(
    async (profile: ProfileLineItem): Promise<void> => {
      try {
        setIsSocialProAdminLoadingProfile(true);
        // const encodedProfile = encodeProfile(profile);
        // await postApiData("deleteProfile", "profile", encodedProfile);
        setProfiles((prevProfiles) =>
          prevProfiles.filter((p) => p.id !== profile.id),
        );
      } catch (e: any) {
        setError("Could not delete profile");
      } finally {
        setIsSocialProAdminLoadingProfile(false);
      }
    },
    [postApiData, setProfiles, setIsSocialProAdminLoadingProfile, setError],
  );

  const changePassword = useCallback(
    async (profile: ProfileLineItem, newPassword: string): Promise<void> => {
      try {
        setIsSocialProAdminLoadingProfile(true);
        await getApiData("changePassword", "profile", profile.id, {
          newPassword,
        });
        setProfiles((prevProfiles) =>
          prevProfiles.filter((p) => p.id !== profile.id),
        );
      } catch (e: any) {
        setError("Could not delete profile");
      } finally {
        setIsSocialProAdminLoadingProfile(false);
      }
    },
    [postApiData, setProfiles, setIsSocialProAdminLoadingProfile, setError],
  );

  useEffect(() => {
    if (organisationId) {
      listProfiles(organisationId);
    } else {
      setProfiles([]);
    }
  }, [listProfiles, setProfiles, organisationId]);

  return {
    changePassword,
    createProfile,
    deleteProfile,
    error,
    isSocialProAdminLoadingProfile,
    listProfiles,
    profiles,
    updateProfile,
  };
};
