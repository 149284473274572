"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.decodeSustainabilitysAnalyticsModel = exports.decodeSustainabilityTargetAnalytics = exports.decodePolicyAnalyticsModel = exports.decodePolicyTargetAnalytics = exports.decodeSocialSpendAnalyticsForPackageModel = exports.decodeSocialSpendTargetAnalyticsForPackage = exports.decodeSocialSpendAnalyticsModel = exports.decodeSocialSpendTargetAnalytics = exports.decodeLabourHoursAnalyticsForEmployeeForTargetModel = exports.decodeLabourHoursAnalyticsForEmployeeLineItem = exports.decodeLabourHoursAnalyticsForEmployeeModel = exports.decodeLabourHoursAnalyticsForEmployee = exports.decodeLabourHoursAnalyticsForPackageModel = exports.decodeLabourHourTargetAnalyticsForPackage = exports.decodeLabourHoursAnalyticsModel = exports.decodeLabourHourTargetAnalytics = void 0;
var labourHour_1 = require("../labourHour");
var decodeLabourHourTargetAnalytics = function (data) {
    return {
        actual: data.actual,
        id: data.id,
        monthReportedValue: data.month_reported_value,
        progress: data.progress,
        targetDescription: data.target_description,
        targetType: data.target_type,
        targetValue: data.target_value,
        totalReportedValue: data.total_reported_value,
    };
};
exports.decodeLabourHourTargetAnalytics = decodeLabourHourTargetAnalytics;
var decodeLabourHoursAnalyticsModel = function (data) {
    return {
        targets: data.targets.map(function (target) {
            return (0, exports.decodeLabourHourTargetAnalytics)(target);
        }),
    };
};
exports.decodeLabourHoursAnalyticsModel = decodeLabourHoursAnalyticsModel;
var decodeLabourHourTargetAnalyticsForPackage = function (data, getImgUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var contractorLogo;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getImgUrl(data.contractor_logo)];
            case 1:
                contractorLogo = _a.sent();
                return [2 /*return*/, {
                        actual: data.actual,
                        contractorLogo: contractorLogo,
                        contractorName: data.contractor_name,
                        id: data.id,
                        monthReportedValue: data.month_reported_value,
                        packageNumber: data.package_number,
                        progress: data.progress,
                        targetValue: data.target_value,
                        totalReportedValue: data.total_reported_value,
                    }];
        }
    });
}); };
exports.decodeLabourHourTargetAnalyticsForPackage = decodeLabourHourTargetAnalyticsForPackage;
var decodeLabourHoursAnalyticsForPackageModel = function (data, getImgUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var targets, summary;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Promise.all(data.targets.map(function (target) {
                    return (0, exports.decodeLabourHourTargetAnalyticsForPackage)(target, getImgUrl);
                }))];
            case 1:
                targets = _a.sent();
                summary = (0, exports.decodeLabourHourTargetAnalytics)(data.summary);
                return [2 /*return*/, {
                        summary: summary,
                        targets: targets,
                    }];
        }
    });
}); };
exports.decodeLabourHoursAnalyticsForPackageModel = decodeLabourHoursAnalyticsForPackageModel;
var decodeLabourHoursAnalyticsForEmployee = function (data) {
    return {
        employeeIdentifier: data.employee_identifier,
        employeeName: data.employee_name,
        id: data.id,
        primaryEmployer: data.primary_employer,
        subcontractor: data.subcontractor,
        totalHoursStudied: data.total_hours_studied,
        totalHoursWorked: data.total_hours_worked,
    };
};
exports.decodeLabourHoursAnalyticsForEmployee = decodeLabourHoursAnalyticsForEmployee;
var decodeLabourHoursAnalyticsForEmployeeModel = function (data, getImageUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var summary;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, exports.decodeLabourHourTargetAnalyticsForPackage)(data.summary, getImageUrl)];
            case 1:
                summary = _a.sent();
                return [2 /*return*/, {
                        summary: summary,
                        targets: data.targets.map(function (target) {
                            return (0, exports.decodeLabourHoursAnalyticsForEmployee)(target);
                        }),
                    }];
        }
    });
}); };
exports.decodeLabourHoursAnalyticsForEmployeeModel = decodeLabourHoursAnalyticsForEmployeeModel;
var decodeLabourHoursAnalyticsForEmployeeLineItem = function (data) {
    return {
        employeeIdentifier: data.employee_identifier,
        employeeName: data.employee_name,
        id: data.id,
        primaryEmployer: data.primary_employer,
        subcontractor: data.subcontractor,
        totalHoursStudied: data.total_hours_studied,
        totalHoursWorked: data.total_hours_worked,
    };
};
exports.decodeLabourHoursAnalyticsForEmployeeLineItem = decodeLabourHoursAnalyticsForEmployeeLineItem;
var decodeLabourHoursAnalyticsForEmployeeForTargetModel = function (data) {
    return {
        summary: (0, exports.decodeLabourHoursAnalyticsForEmployeeLineItem)(data.summary),
        targets: data.targets.map(function (target) { return (0, labourHour_1.decodeLabourHourForTarget)(target); }),
    };
};
exports.decodeLabourHoursAnalyticsForEmployeeForTargetModel = decodeLabourHoursAnalyticsForEmployeeForTargetModel;
var decodeSocialSpendTargetAnalytics = function (data) {
    return {
        id: data.id,
        monthReportedValue: data.month_reported_value,
        progress: data.progress,
        targetDescription: data.target_description,
        targetType: data.target_type,
        targetValue: data.target_value,
        totalReportedValue: data.total_reported_value,
    };
};
exports.decodeSocialSpendTargetAnalytics = decodeSocialSpendTargetAnalytics;
var decodeSocialSpendAnalyticsModel = function (data) {
    return {
        targets: data.targets.map(function (target) {
            return (0, exports.decodeSocialSpendTargetAnalytics)(target);
        }),
    };
};
exports.decodeSocialSpendAnalyticsModel = decodeSocialSpendAnalyticsModel;
var decodeSocialSpendTargetAnalyticsForPackage = function (data, getImgUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var contractorLogo;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getImgUrl(data.contractor_logo)];
            case 1:
                contractorLogo = _a.sent();
                return [2 /*return*/, {
                        contractorLogo: contractorLogo,
                        contractorName: data.contractor_name,
                        id: data.id,
                        monthReportedValue: data.month_reported_value,
                        packageNumber: data.package_number,
                        progress: data.progress,
                        targetValue: data.target_value,
                        totalReportedValue: data.total_reported_value,
                    }];
        }
    });
}); };
exports.decodeSocialSpendTargetAnalyticsForPackage = decodeSocialSpendTargetAnalyticsForPackage;
var decodeSocialSpendAnalyticsForPackageModel = function (data, getImgUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var targets, summary;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Promise.all(data.targets.map(function (target) {
                    return (0, exports.decodeSocialSpendTargetAnalyticsForPackage)(target, getImgUrl);
                }))];
            case 1:
                targets = _a.sent();
                summary = (0, exports.decodeSocialSpendTargetAnalytics)(data.summary);
                return [2 /*return*/, {
                        summary: summary,
                        targets: targets,
                    }];
        }
    });
}); };
exports.decodeSocialSpendAnalyticsForPackageModel = decodeSocialSpendAnalyticsForPackageModel;
var decodePolicyTargetAnalytics = function (data) {
    return {
        expectedSubmissions: data.expected_submissions,
        id: data.id,
        isProjectLevelPolicy: data.is_project_level_policy,
        policyDescription: data.policy_description,
        policyType: data.policy_type,
        progress: data.progress,
        totalSubmissions: data.total_submissions,
        uri: data.uri,
    };
};
exports.decodePolicyTargetAnalytics = decodePolicyTargetAnalytics;
var decodePolicyAnalyticsModel = function (data) {
    return {
        targets: data.targets.map(function (target) { return (0, exports.decodePolicyTargetAnalytics)(target); }),
    };
};
exports.decodePolicyAnalyticsModel = decodePolicyAnalyticsModel;
var decodeSustainabilityTargetAnalytics = function (data) {
    return {
        category: data.category,
        greenMetricId: data.green_metric_id,
        id: data.id,
        pcIncrease: data.pc_increase,
        thisMonth: data.this_month,
        toDate: data.to_date,
        type: data.type,
        uri: data.uri,
    };
};
exports.decodeSustainabilityTargetAnalytics = decodeSustainabilityTargetAnalytics;
var decodeSustainabilitysAnalyticsModel = function (data) {
    return {
        targets: data.targets.map(function (target) {
            return (0, exports.decodeSustainabilityTargetAnalytics)(target);
        }),
    };
};
exports.decodeSustainabilitysAnalyticsModel = decodeSustainabilitysAnalyticsModel;
