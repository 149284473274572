import { useProjectContext } from "@hooks/context/useProjectContext";
import { useMainChartData } from "@hooks/crud/dashboard/useMainChartData";
import { useForecastModel } from "@hooks/crud/forecastData/useForecastData";
import { Box, Grid } from "@mui/material";
import { ForecastChart } from "@stories/molecules/ForecastChart/ForecastChart";
import ForecastModal from "@stories/molecules/ForecastTable/ForecastModal/ForecastModal";
import { ForecastTable } from "@stories/molecules/ForecastTable/ForecastTable";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";
import { useCallback, useState } from "react";
import { ChartTargetType } from "social-pro-common/entities/analysis";
import { ForecastModelLineItem } from "social-pro-common/interfaces/forecastData";
import { getReportMonths, stringToDate } from "social-pro-common/utils/date";

import {
  ChartRangeType,
  DashboardChartRangeTabs,
} from "../DashboardChart/DashboardChartRangeTabs";
import { DashboardChartTabs } from "../DashboardChart/DashboardChartTabs";

export const Forecast = () => {
  const [open, setOpen] = useState(false);
  const [selectedForecastModel, setSelectedForecastModel] =
    useState<ForecastModelLineItem>();
  const { contractorPackage, isProjectLoading, selectedProject } =
    useProjectContext();

  const {
    forecastModels,
    isForecastLoading,
    isSubmittingForecast,
    updateForecastModel,
  } = useForecastModel(selectedProject, contractorPackage);

  const { chartData, isChartDataLoading } = useMainChartData(
    selectedProject?.id,
    contractorPackage?.id,
  );

  const handleUpdateForecastModel = async (
    forecastModel: ForecastModelLineItem,
  ) => {
    await updateForecastModel([forecastModel]);
  };

  const [selectedTarget, setSelectedTarget] = useState<ChartTargetType>(
    ChartTargetType.Hour,
  );
  const [selectedRange, setSelectedRange] = useState<ChartRangeType>(
    ChartRangeType.TwelveMonth,
  );

  const handleChangeTarget = (
    event: React.SyntheticEvent,
    newValue: ChartTargetType,
  ) => {
    if (newValue) {
      setSelectedTarget(newValue);
    }
  };

  const handleChangeRange = (
    event: React.SyntheticEvent,
    newValue: ChartRangeType,
  ) => {
    if (newValue) {
      setSelectedRange(newValue);
    }
  };

  const reportMonths = contractorPackage
    ? getReportMonths(
        stringToDate(contractorPackage.packageStartDate),
        stringToDate(contractorPackage.packageEndDate),
      )
    : selectedProject?.projectReportMonths;

  const selectedChartData = (() => {
    if (chartData) {
      switch (selectedTarget) {
        default:
        case ChartTargetType.Hour:
          return chartData.labourHours;
        case ChartTargetType.Spend:
          return chartData.socialSpend;
        case ChartTargetType.GreenMetric:
          return chartData.greenMetrics;
      }
    }
  })();

  const chartUnits = (() => {
    if (chartData) {
      switch (selectedTarget) {
        default:
        case ChartTargetType.Hour:
          return "Hours";
        case ChartTargetType.Spend:
          return "Dollars";
        case ChartTargetType.GreenMetric:
          return "Submissions";
      }
    }
    return "";
  })();

  const loading = isProjectLoading || isForecastLoading || isChartDataLoading;

  const handleOpen = useCallback(
    (forecast: ForecastModelLineItem) => {
      setSelectedForecastModel(forecast);
      setOpen(true);
    },
    [setOpen, setSelectedForecastModel],
  );
  return (
    <Grid
      container
      md={12}
      sx={{
        margin: "0",
        marginBottom: "0",
        width: "100%",
      }}
    >
      <PageHeader
        loading={loading}
        title="Forecasting"
        subTitle={"Add target projections"}
      />
      <Grid
        container
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          padding: "20px 40px 10px",
        }}
      >
        <Grid item md={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <DashboardChartTabs
              isLoading={loading}
              value={selectedTarget}
              project={selectedProject}
              handleChange={handleChangeTarget}
            />
            <DashboardChartRangeTabs
              isLoading={loading}
              value={selectedRange}
              handleChange={handleChangeRange}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            padding: "0 !important",
            textAlign: "right",
          }}
        >
          <ForecastChart
            loading={loading}
            chartRangeType={selectedRange}
            chartUnits={chartUnits}
            reportMonths={reportMonths}
            forecastModals={forecastModels}
            chartData={selectedChartData}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          mt={2}
          mb={2}
          sx={{
            padding: "0 !important",
            textAlign: "right",
          }}
        >
          <ForecastTable
            loading={loading}
            disabled={isSubmittingForecast}
            forecastData={forecastModels}
            chartType={selectedTarget}
            project={selectedProject}
            contractorPackage={contractorPackage}
            editForecastModel={handleOpen}
            updateForecastModel={handleUpdateForecastModel}
          />
          {open && contractorPackage && selectedForecastModel ? (
            <ForecastModal
              open={open}
              loading={loading}
              contractorPackage={contractorPackage}
              existingForecast={selectedForecastModel}
              updateForecast={async (
                forecastDataModel: ForecastModelLineItem,
              ) => {
                await handleUpdateForecastModel(forecastDataModel);
                setSelectedForecastModel(undefined);
                setOpen(false);
              }}
              handleClose={() => {
                setSelectedForecastModel(undefined);
                setOpen(false);
              }}
            />
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};
