import { searchApiData } from "@hooks/utils/api";
import { catchSentryError } from "@utils/sentry";
import { useState, useCallback } from "react";
import { decodeEmployee } from "social-pro-common/decoders/contractorEmployee";
import { ContractorEmployee } from "social-pro-common/entities/contractorEmployee";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";

const useAssignedSearchEmployee = () => {
  const [isEmployeeLoading, setIsEmployeeLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const searchAssignedEmployeesByNameOrCode = useCallback(
    async (
      projectId: string,
      contractorPackageId: string,
      employeeIds: string[],
    ): Promise<EmployeeLineItem[]> => {
      try {
        setIsEmployeeLoading(true);
        const employees = await searchApiData(
          "searchAssignedEmployeesByNameOrCode",
          "projectEmployee",
          employeeIds,
          {
            contractorPackageId,
            projectId,
          },
        );

        return employees.data.map((employee) =>
          decodeEmployee(employee as ContractorEmployee),
        );
      } catch (error) {
        catchSentryError(error);
        setError("Could not search employees");
        return [];
      } finally {
        setIsEmployeeLoading(false);
      }
    },
    [searchApiData, decodeEmployee, catchSentryError],
  );

  return {
    error,
    isEmployeeLoading,
    searchAssignedEmployeesByNameOrCode,
  };
};

export default useAssignedSearchEmployee;
