import { useLabourHourTemplateExport } from "@hooks/crud/downloadFiles/useLabourHourTemplateExport";
import useAssignedSearchEmployee from "@hooks/crud/projectEmployee/useAssignedSearchEmployee";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import Iconify from "@stories/atoms/Iconify/Iconify";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { catchSentryError } from "@utils/sentry";
import { readXlsxFileToLabourHours } from "@utils/xlsx/labourHours";
import { useRef, useState } from "react";
import { LabourHourLineItem } from "social-pro-common/interfaces/labourHour";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import { ProjectLineItem } from "social-pro-common/interfaces/project";

import { PdfDownlodDialog } from "../PdfDownlodDialog/PdfDownlodDialog";

export enum ImportStep {
  Init = "Init",
  Parse = "Parse",
  Upload = "Upload",
}

interface LabourHourImportProps {
  open: boolean;
  loading: boolean;
  step: ImportStep;
  project?: ProjectLineItem;
  contractorPackageId?: string;
  userOrganisation?: OrganisationLineItem;
  existingLabourHours: LabourHourLineItem[];
  reportId: string;
  setIsOpen: (open: boolean) => void;
  setStep: (step: ImportStep) => void;
  upsertLabourHours: (labourHours: LabourHourLineItem[]) => Promise<void>;
}

export default function LabourHourImport({
  contractorPackageId,
  existingLabourHours,
  loading,
  open,
  project,
  reportId,
  setIsOpen,
  setStep,
  step,
  upsertLabourHours,
  userOrganisation,
}: LabourHourImportProps) {
  const [uploadMessage, setUploadMessage] = useState("Uploading...");
  const fileInput = useRef<HTMLInputElement | null>(null);

  const { downloadProgress, downloadStep, getLabourHourTemplateExcel } =
    useLabourHourTemplateExport();

  const { searchAssignedEmployeesByNameOrCode } = useAssignedSearchEmployee();

  const onFileInput = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      e.currentTarget?.files &&
      e.currentTarget?.files.length > 0 &&
      contractorPackageId
    ) {
      if (project && userOrganisation) {
        setIsOpen(true);
        setStep(ImportStep.Parse);
        const file = e.currentTarget.files[0];
        try {
          const xlsLabourHoursReponse = await readXlsxFileToLabourHours(
            project,
            reportId,
            contractorPackageId,
            userOrganisation,
            file,
            searchAssignedEmployeesByNameOrCode,
          );

          if (xlsLabourHoursReponse.errorMessage) {
            alert(xlsLabourHoursReponse.errorMessage);
          } else {
            const dedupedLabourHours = xlsLabourHoursReponse.labourHours.reduce(
              (acc: LabourHourLineItem[], lh: LabourHourLineItem) => {
                const existingLH = acc.find(
                  (l) => l.employeeId === lh.employeeId,
                );
                if (existingLH) {
                  const dupeRemove = acc.filter(
                    (a) => a.employeeId !== lh.employeeId,
                  );
                  return [
                    ...dupeRemove,
                    {
                      ...existingLH,
                      amountOfHoursWorked:
                        existingLH.amountOfHoursWorked + lh.amountOfHoursWorked,
                    },
                  ];
                }
                return [...acc, lh];
              },
              existingLabourHours as LabourHourLineItem[],
            );

            setStep(ImportStep.Upload);

            const totalLabourHours = dedupedLabourHours.length;
            const labourHourData = [];
            while (dedupedLabourHours.length > 0)
              labourHourData.push(dedupedLabourHours.splice(0, 50));

            let count = 0;
            for (const labourHour of labourHourData) {
              count += labourHour.length;
              setUploadMessage(
                `Uploading ${count}/${totalLabourHours} labour hours...`,
              );
              await upsertLabourHours(labourHour);
            }
            window.location.reload();
          }
        } catch (error: any) {
          alert(error);
          catchSentryError(error);
        }
        setIsOpen(false);
        setStep(ImportStep.Init);
      }
    }
  };
  return (
    <>
      <Dialog maxWidth="md" open={open} className="bgBlueOverlay">
        <DialogTitle>Import Labour Hours</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Stack spacing={2}>
              {step === ImportStep.Init ? (
                <Grid
                  item
                  md={12}
                  sx={{
                    display: "flex",
                    gap: "30px",
                    justifyContent: "center",
                    margin: "0",
                    padding: "50px 30px 50px",
                  }}
                >
                  <IconButton
                    className="closeBtn"
                    aria-label="close"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                    sx={{
                      "&:hover": {
                        background: "#143E7C",
                      },
                      background: "#000",
                      color: "#fff",
                      position: "absolute",
                      right: 14,
                      top: 11,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <StyledButton
                    loading={false}
                    variant="contained"
                    disabled={loading}
                    className="blackBtn grey-outline-btn"
                    startIcon={<Iconify icon="mdi:microsoft-excel" />}
                    onClick={async () => {
                      if (project && contractorPackageId) {
                        await getLabourHourTemplateExcel(
                          project.id,
                          contractorPackageId,
                          reportId,
                        );
                      }
                    }}
                  >
                    Download Template
                  </StyledButton>
                  <StyledButton
                    loading={false}
                    onClick={onFileInput}
                    variant="contained"
                    disabled={loading}
                    className="blackBtn"
                    startIcon={<CloudUploadIcon />}
                  >
                    <input
                      hidden
                      ref={fileInput}
                      accept="*/xlsx"
                      type="file"
                      onChange={onUpload}
                    />
                    {"Upload"}
                  </StyledButton>
                </Grid>
              ) : (
                <>
                  <Box sx={{ margin: "auto !important", padding: "10%" }}>
                    <CircularProgress />
                  </Box>
                  <Typography align="center">
                    {step === ImportStep.Parse ? "Parsing..." : uploadMessage}
                  </Typography>
                </>
              )}
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
      <PdfDownlodDialog
        downloadStep={downloadStep}
        total={downloadProgress}
        title={"Labour Hour Template Export"}
      />
    </>
  );
}
